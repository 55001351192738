import fetch from "./xhr/index";

/**
 * 获取员工出勤列表
 * @parama
*/
export const getAttendanceList = param => fetch("GET", "/customerService/service/attendance", param);

/**
 * 获取员工出勤列表-弹窗
 * @parama
*/
export const getAttendanceHead = param => fetch("GET", "/customerService/service/attendance", param);

/**
 * 获取客服等级列表
 * @parama
*/
 export const getSerLevelList = param => fetch("GET", "/ums/umsServiceLevel/list", param);

/**
 * 获取客服等级-列表排序
 * @parama
*/
export const getSerLevelSort = param => fetch("GET", "/ums/umsServiceLevel/sort", param);

/**
 * 获取客服等级-新增等级
 * @parama
*/
export const addSerLevel = param => fetch("POST", "/ums/umsServiceLevel/addServiceLevel", param);

/**
 * 获取客服等级-编辑等级
 * @parama
*/
export const editSerLevel = param => fetch("POST", "/ums/umsServiceLevel/updateServiceLevel", param);

/**
 * 获取客服等级-删除等级
 * @parama
*/
export const deleteSerLevel = param => fetch("GET", "/ums/umsServiceLevel/deleteById", param);

/**
 * 客服画像概览-图表
 * @parama
*/
export const serPieData = param => fetch("GET", "/ums/umsServiceLevel/distributionService", param);

/**
 * 客服画像概览-top排行
 * @parama
*/
export const serviceTop = param => fetch("GET", "/ums/umsServiceLevel/serviceTop", param);

/**
 * 客服画像概览-客服服务数据
 * @param
 */
export const serviceKPIList = param => fetch("GET", "/excel/serviceKPI", param);
 
/**
 * 获取设置列表
 * @parama
 */
export const list = param => fetch("GET", "/performance/performance/getList", param);

/**
 * 新增绩效设置
 * @parama
 */
export const add = param => fetch("POST", "/performance/performance/addSetting", param);

/**
 * 获取详情
 * @parama
 */
export const detail = param => fetch("GET", "/performance/performance/getDetail", param);

/**
 * 新增绩效设置
 * @parama
 */
export const deleted = param => fetch("POST", "/performance/performance/deleteSetting", param);

/**
 * 编辑绩效设置
 * @parama
 */
export const edit = param => fetch("POST", "/performance/performance/editSetting", param);

/**
 * 查询绩效设置覆盖人员
 * @parama
 */
export const selectSetting = param => fetch("POST", "/performance/performance/selectSetting", param);

/**
 * 更新覆盖人员
 * @parama
 */
export const updateSetting = param => fetch("POST", "/performance/performance/updateSetting", param);

/**
 * 员工收入测算列表
 * @parama
 */
export const wagesList = param => fetch("GET", "/cms/wageCalculate/selectWageCalculate", param);

/**
 * 新增工资项
 * @parama
 */
export const wagesAdd = param => fetch("POST", "/cms/wageCalculate/insertWageCalculate", param);

/**
 * 编辑工资项
 * @parama
 */
export const updateWages = param => fetch("POST", "/cms/wageCalculate/updateWageCalculate", param);

/**
 * 工资项详情
 * @parama
 */
export const selectById = param => fetch("GET", "/cms/wageCalculate/selectById", param);

/**
 * 异常扣款详情详情
 * @parama
 */
export const selectDeductList = param => fetch("GET", "/cms/wageCalculate/selectDeductList", param);

/**
 * 收入测算-列表
 * @param
 */
 export const incomeCalList = param => fetch("GET", "/ums/umsServiceLevel/calculated", param);

/**
 * 员工画像-详情/个人档案
 * @param
 */
 export const portraitDetail = param => fetch("GET", "/performance/performance/employeePortrait", param);
 
 /**
 * 个人资料-扣款-奖励-补贴弹窗表格
 * @param
 */
export const priceDetail = param => fetch('GET', '/cms/wageCalculate/getWageCalculateList', param)
 
/**
 * 个人月产能明细
 * @param
 */
export const capacityDetail = param => fetch('GET', '/capacity/serviceCapacity/capacityDetail', param)
 
/**
 * 个人日产能明细
 * @param
 */
export const capacityDayDetail = param => fetch('GET', '/capacity/serviceCapacity/capacityDayDetail', param)